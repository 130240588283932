/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgChevronDown } from "react-icons/cg";
import i18next from "i18next";
import "./Navbar.styles.css";
import { useTranslation } from "react-i18next";

export const Navbar = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const language = i18next.language;
  const navigate = useNavigate();

  const handleDropdown = (id) => {
    if (window.innerWidth > 1280) {
      return;
    }
    for (let i = 1; i <= 7; i++) {
      if (i === id) {
        document
          .getElementById(`dropdown-${id}`)
          .classList.toggle("dropdownOpen");
      }
    }
  };

  return (
    <header className="c-header c-header.is-scrolling">
      <div className="header-container container">
        <div className="logo-container">
          <Link to="/">
            <img src="/logo.svg" alt="Cevher Logo" />
          </Link>
        </div>
        <div
          className="header-hamburger"
          id="hamburger"
          onClick={() => {
            if (!isOpen) {
              document.getElementById("hamburger").classList.add("is-active");
              setIsOpen(true);
            } else {
              setIsOpen(false);
              document
                .getElementById("hamburger")
                .classList.remove("is-active");
            }
          }}
        >
          <GiHamburgerMenu color="#4bc0e9" />
        </div>
        <div className="list-container">
          <ul className="list">
            <li className="list-item">
              <div
                className="list-item-link"
                onClick={() => {
                  handleDropdown(1);
                }}
              >
                {t("corporate")}
              </div>
              <ul
                className="list-dropdown list-dropdown-navigation"
                id="dropdown-1"
              >
                <li className="list-dropdown-item">
                  <Link to="/corporate/about" className="list-dropdown-link">
                    {t("aboutus")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link to="/corporate/history" className="list-dropdown-link">
                    {t("ourhistory")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link to="/corporate/clients" className="list-dropdown-link">
                    {t("ourcustomers")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link to="/corporate/values" className="list-dropdown-link">
                    {t("visionmissionvaluesandethics")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/products/social-responsibility"
                    className="list-dropdown-link"
                    alt="Social Responsibility"
                  >
                    {t("socialresponsibility")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <a
                    href="https://cevher.com/static/docs/kvkk-request.pdf"
                    target="_blank"
                    className="list-dropdown-link"
                    rel="noreferrer"
                  >
                    {t("sustainability-protection-kvkk-request")}
                  </a>
                </li>
                <li className="list-dropdown-item">
                  <a
                    href="https://cevher.com/static/docs/kvkk-info.pdf"
                    target="_blank"
                    className="list-dropdown-link"
                    rel="noreferrer"
                  >
                    {t("sustainability-protection-kvkk-info")}
                  </a>
                </li>
                <li className="list-dropdown-item">
                  <Link to="/corporate/information-community-system" className="list-dropdown-link">
                    {t("information-community-system")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-item">
              <div
                className="list-item-link"
                onClick={() => {
                  handleDropdown(2);
                }}
              >
                {t("products")}
              </div>
              <ul
                className="list-dropdown list-dropdown-navigation"
                id="dropdown-2"
              >
                <li className="list-dropdown-item">
                  <Link to="/products/oem" className="list-dropdown-link">
                    {t("alloywheelsforoem")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/products/after-market"
                    className="list-dropdown-link"
                  >
                    {t("alloywheelsforaftermarket")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/products/chassis-and-powertrain"
                    className="list-dropdown-link"
                  >
                    {t("chassisandpowertrain")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-item">
              <div
                className="list-item-link"
                onClick={() => {
                  handleDropdown(3);
                }}
              >
                {t("developmentandproduction")}
              </div>
              <ul
                className="list-dropdown list-dropdown-navigation"
                id="dropdown-3"
              >
                <li className="list-dropdown-item">
                  <Link
                    to="/development-and-production/rnd-engineering"
                    className="list-dropdown-link"
                  >
                    {t("r&dandengineering")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/development-and-production/quality"
                    className="list-dropdown-link"
                  >
                    {t("quality")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/development-and-production/production"
                    className="list-dropdown-link"
                  >
                    {t("production")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link to="/industrial-design" className="list-dropdown-link">
                    {t("industrialdesign")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-item">
              <div
                className="list-item-link"
                onClick={() => {
                  handleDropdown(4);
                }}
              >
                {t("sustainability")}
              </div>
              <ul
                className="list-dropdown list-dropdown-navigation"
                id="dropdown-4"
              >
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/sustainability-report"
                    className="list-dropdown-link"
                  >
                    {t("sustainabilityreport")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/environment-and-energy-policy"
                    className="list-dropdown-link"
                  >
                    {t("environmentandenergypolicy")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/iso-14001-2015-scope"
                    className="list-dropdown-link"
                  >
                    {t("isoscope")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/environmental-policy-and-activities"
                    className="list-dropdown-link"
                  >
                    {t("environmentalpolicyandactivities")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/energy-management-policy"
                    className="list-dropdown-link"
                  >
                    {t("energyactivities")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/green-purchasing"
                    className="list-dropdown-link"
                  >
                    {t("green-purchasing-policy")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/development-and-production/quality"
                    className="list-dropdown-link"
                  >
                    {t("quality-documents")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/occupational-health-and-safety-policy-and-activities"
                    className="list-dropdown-link"
                  >
                    {t("occupationalhealthandsafetypolicy")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/social-compliance-policy"
                    className="list-dropdown-link"
                  >
                    {t("socialcompliancepolicy")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/modern-slavery-policy"
                    className="list-dropdown-link"
                  >
                    {t("slavery-policy-title")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/information-security-policy"
                    className="list-dropdown-link"
                  >
                    {t("informationsecuritypolicy")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/supplier-sustainability-policy"
                    className="list-dropdown-link"
                  >
                    {t("supplier-sustainability-policy")}
                  </Link>
                </li>
                {/* <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/supplier-sustainability-policy"
                    className="list-dropdown-link"
                  >
                    {t("supplier-sustainability-policy")}
                  </Link>
                </li> */}
                <li className="list-dropdown-item">
                  <Link
                    to="/sustainability/protection-of-personal-data"
                    className="list-dropdown-link"
                  >
                    {t("protectionofpersonaldata")}
                  </Link>
                </li>
              </ul>
            </li>




            <li className="list-item">
              <div
                className="list-item-link"
                onClick={() => {
                  handleDropdown(5);
                }}
              >
                {t("career")}
              </div>
              <ul
                className="list-dropdown list-dropdown-navigation"
                id="dropdown-5"
              >
                <li className="list-dropdown-item">
                  <Link to="/career" className="list-dropdown-link">
                    {t("careeropportunities")}
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link to="/student-programs" className="list-dropdown-link">
                    {t("studentprograms")}
                  </Link>
                </li>
              </ul>
            </li>


            <li className="list-item">
              <div
                className="list-item-link"
                onClick={() => {
                  navigate("/news");
                }}
              >
                {t("news")}
              </div>
            </li>
            <li className="list-item">
              <div
                className="list-item-link"
                onClick={() => {
                  handleDropdown(7);
                }}
              >
                {t("investor-relations")}
              </div>
              <ul
                className="list-dropdown list-dropdown-navigation"
                id="dropdown-7"
              >
                <li className="list-dropdown-item">
                  <Link to="/investor-relations/initial-public-offering" className="list-dropdown-link">
                    {t("initial-public-offering")}
                  </Link>
                </li>
              </ul>
            </li>
            <li className="list-item">
              <div
                className="list-item-link"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                {t("contact")}
              </div>
            </li>
            <li className="list-item">
              <Link
                to="#"
                className="list-item-link"
                onClick={() => handleDropdown(6)}
              >
                <img
                  src={require(language === "en"
                    ? "../../assets/flags/gb.603f987e.webp"
                    : "../../assets/flags/tr.3e706421.webp")}
                  alt="Flag"
                  style={{ width: 21, height: 14 }}
                />
                <CgChevronDown color="black" size={20} />
              </Link>
              <ul
                className="list-dropdown list-dropdown-navigation"
                id="dropdown-6"
              >
                <li className="list-dropdown-item">
                  <Link
                    to="#"
                    className="list-dropdown-link"
                    onClick={() => {
                      setIsOpen(false);
                      handleDropdown(6);
                      document
                        .getElementById("hamburger")
                        .classList.remove("is-active");
                      i18next.changeLanguage("tr");
                    }}
                  >
                    Türkçe
                  </Link>
                </li>
                <li className="list-dropdown-item">
                  <Link
                    to="#"
                    className="list-dropdown-link"
                    onClick={() => {
                      setIsOpen(false);
                      handleDropdown(6);
                      document
                        .getElementById("hamburger")
                        .classList.remove("is-active");
                      i18next.changeLanguage("en");
                    }}
                  >
                    English
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};
