import i18next from "i18next";
import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";
import Timeline from "../../Components/Timeline";
import { News_EN } from "../../Constants/News";
import "./Home.styles.css";



const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const language = i18next.language;

  useEffect(() => {
    const updateMetaTags = () => {
      const newTitle = `Cevher | ${t("wheels-aluminium")}`;
      const newDescription = t("desc-home");

      // Update document title
      document.title = newTitle;

      // Update og:title
      const ogTitleMeta = document.querySelector('meta[property="og:title"]');
      if (ogTitleMeta) {
        ogTitleMeta.setAttribute("content", newTitle);
      } else {
        const meta = document.createElement("meta");
        meta.setAttribute("property", "og:title");
        meta.setAttribute("content", newTitle);
        document.head.appendChild(meta);
      }

      // Update og:description
      const ogDescriptionMeta = document.querySelector('meta[property="og:description"]');
      if (ogDescriptionMeta) {
        ogDescriptionMeta.setAttribute("content", newDescription);
      } else {
        const meta = document.createElement("meta");
        meta.setAttribute("property", "og:description");
        meta.setAttribute("content", newDescription);
        document.head.appendChild(meta);
      }

      // Update description
      const descriptionMeta = document.querySelector('meta[name="description"]');
      if (descriptionMeta) {
        descriptionMeta.setAttribute("content", newDescription);
      } else {
        const meta = document.createElement("meta");
        meta.setAttribute("name", "description");
        meta.setAttribute("content", newDescription);
        document.head.appendChild(meta);
      }

    };

    updateMetaTags();
  }, [language, t]);

  useEffect(() => {

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const currentSlide = document.querySelector(`.carousel-item[data-index="${index}"]`);
    if (currentSlide) {
      const lazyImages = currentSlide.querySelectorAll("img[data-src]");
      lazyImages.forEach((img) => {
        img.src = img.dataset.src; // LazyLoad olmadan kaynak ataması yap
        img.removeAttribute("data-src"); // LazyLoad için olan işareti kaldır
      });
    }
  }, [index]);


  const bannerSrc = isMobile
    ? require("../../assets/content/cevher_slider_banner-2.cbc16912-1.webp")
    : require("../../assets/content/cevher_slider_banner-2.cbc16912.webp");

  return (
    <div>
      <Helmet>
        <link rel="preload" as="image" href={bannerSrc} />
      </Helmet>
      <Navbar />
      <div className="c-wrapper">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item className="carousel-item" data-index="0">
            <img
              data-src={require(`../../assets/slider/${isMobile
                ? "sliding_banner_4-1.webp"
                : "sliding_banner_4-2.webp"
                }`)}
              alt="Cevher"
              className="c-slider-image"
            />
            <div className="c-carousel-caption">
              <h1 className="c-slider-title">{t("wecarenature")}</h1>
              <p className="c-slider-description">{t("wecarenaturedesc")}</p>
              <div
                className="c-button"
                style={{ marginTop: "1rem" }}
                onClick={() => navigate("/sustainability/sustainability-report")}
              >
                {t("sustainabilityactivies")}
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item className="carousel-item" data-index="1">
            <img
              data-src={require(`../../assets/content/${isMobile
                ? "cevher_slider_banner-1.df2341f8-1.webp"
                : "cevher_slider_banner-1.df2341f8.webp"
                }`)}
              alt="Cevher"
              className="c-slider-image"
            />
            <div className="c-carousel-caption">
              <h1 className="c-slider-title">{t("fromdesigntoperfection")}</h1>
              <p className="c-slider-description">{t("fromdesigntoperfectiondesc")}</p>
              <div className="carousel-buttons">
                <div className="c-button" onClick={() => navigate("/products")}>
                  {t("products")}
                </div>
              </div>
            </div>/
          </Carousel.Item>

          <Carousel.Item className="carousel-item" data-index="2">
            {/* Görsel */}
            <img
              data-src={bannerSrc}
              alt="Cevher"
              className="c-slider-image"
            />
            <div className="c-carousel-caption">
              <h1 className="c-slider-title">{t("colorofautomative")}</h1>
              <p className="c-slider-description">{t("colorofautomativedesc")}</p>
              <div
                className="c-button"
                style={{ marginTop: "1rem" }}
                onClick={() => navigate("/corporate/about")}
              >
                Cevher
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <div className="c-container">
          <div className="c-together-container">
            <div className="c-container-text-wrapper">
              <div className="c-container-text">{t("workwithworldwide")}</div>
            </div>
            <div className="c-container-img-container">
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Volkswagen"
                  width={75}
                  height={75}
                  src={require("../../assets/VW-Logo.webp")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo"
                  alt="Audi"
                  width={75}
                  height={75}
                  src={require("../../assets/Audi-Logo.webp")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Seat"
                  width={75}
                  height={75}
                  src={require("../../assets/Seat-Logo.webp")}
                  style={{ maxWidth: "85%" }}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Mercedes"
                  width={75}
                  height={75}
                  src={require("../../assets/mercedes_logo.webp")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Seat"
                  width={75}
                  height={75}
                  src={require("../../assets/clients/skoda_new_resize_2.png")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo"
                  alt="Stellantis"
                  width={75}
                  height={75}
                  src={require("../../assets/Stellantis-Logo.webp")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo"
                  alt="Ford"
                  width={75}
                  height={75}
                  src={require("../../assets/Ford-Logo.webp")}
                />
              </div>
              <div className="c-logo-wrapper">
                <img
                  className="c-container-logo square"
                  alt="Cupra"
                  width={75}
                  height={75}
                  src={require("../../assets/Cupra-Logo.webp")}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="c-corporate">
          <div className="c-container">
            <div className="c-container-column">
              <div className="c-caption-section">{t("corporate")}</div>
              <div className="c-caption-title c-caption-text-center">
                {t("completeperfection")}
              </div>
              <LazyLoad height={'100%'} offset={10}>
                <div className="c-card-row">
                  <div className="card-container">
                    <div className="c-ratio c-ratio--4-3">
                      <img
                        className="c-card-img"
                        alt=""
                        src={require("../../assets/about/manufacture-1.webp")}
                      />
                    </div>
                    <div className="c-card-body">
                      <div className="c-card-title">{t("manufacture")}</div>
                      <div className="c-card-content">
                        {t("manufacture-desc")}
                      </div>
                    </div>
                  </div>
                  <div className="card-container">
                    <div className="c-ratio c-ratio--4-3">
                      <img
                        className="c-card-img"
                        alt=""
                        src={require("../../assets/about/about-2.webp")}
                      />
                    </div>
                    <div className="c-card-body">
                      <div className="c-card-title">{t("employee")}</div>
                      <div className="c-card-content">{t("employee-desc")}</div>
                    </div>
                  </div>
                  <div className="card-container">
                    <div className="c-ratio c-ratio--4-3">
                      <img
                        className="c-card-img"
                        alt=""
                        src={require("../../assets/about/technology-1.webp")}
                      />
                    </div>
                    <div className="c-card-body">
                      <div className="c-card-title">{t("technology")}</div>
                      <div className="c-card-content">{t("technology-desc")}</div>
                    </div>
                  </div>
                </div>
              </LazyLoad>
            </div>
          </div>
          <div className="c-learn-btn-wrapper">
            <Link to="/corporate/about" className="c-button">
              {t("learnmore")}
            </Link>
          </div>
        </div>
        <hr />

        <div className="c-locations-container">
          <LazyLoad height={200} offset={10}>
            <div className="c-container">
              <div
                className="c-caption"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="c-caption-section">
                  {t("customerandproductportfolio")}
                </div>
                <div className="c-caption-title c-caption-white">
                  {t("customerslocation")}
                </div>
                <div
                  className="c-caption-content"
                  style={{ color: "white", textAlign: "center" }}
                >
                  {t("customerslocation-desc")}
                </div>
              </div>
              <div className="c-map-wrapper">
                <img
                  src={require("../../assets/content/customer-map.33908c8b.png")}
                  alt="Map"
                  className="c-map"
                />
                <div className="c-map-locations-content">
                  <ul className="c-map-list">
                    <li className="c-map-list-item">{t("germany")}</li>
                    <li className="c-map-list-item">{t("south-africa")}</li>
                    <li className="c-map-list-item">{t("italy")}</li>
                    <li className="c-map-list-item">{t("poland")}</li>
                    <li className="c-map-list-item">{t("czechia")}</li>
                    <li className="c-map-list-item">{t("spain")}</li>
                    <li className="c-map-list-item">{t("portugal")}</li>
                    <li className="c-map-list-item">{t("slovakia")}</li>
                    <li className="c-map-list-item">{t("turkey")}</li>

                    <li className="c-map-list-item" style={{ marginTop: '20px', fontWeight: 'bold' }}>{t("nominated-2025")}</li>

                    <li className="c-map-list-item">{t("hungary")}</li>
                    <li className="c-map-list-item">{t("france")}</li>
                  </ul>
                </div>

              </div>
            </div>
          </LazyLoad>
        </div>

        <div className="container c-products">
          <LazyLoad height={'100%'} offset={10}>
            <div className="c-row row--no-gutter u-flex-row-reverse">
              <div className="col c-col--lg-6">
                <div className="c-caption-container">
                  <div
                    className="c-caption"
                    style={{ padding: 0, marginBottom: "1.5rem" }}
                  >
                    <div className="c-caption-section">{t("products")}</div>
                    <div className="c-caption-title c-caption-large">
                      {t("ourproducts")}
                    </div>
                    <div className="c-caption-content">
                      {t("ourproducts-desc")}
                    </div>
                  </div>
                  <Link to="/products" className="c-button">
                    {t("details")}
                  </Link>
                </div>
              </div>
              <div className="col c-col--lg-6">
                <div className="c-ratio c-ratio--4-3">
                  <img
                    src={require("../../assets/content/our-products.51d44431.webp")}
                    alt="Product-1"
                    className="c-products-img"
                  />
                </div>
              </div>
            </div>
            <div
              className="c-row row--no-gutter"
              style={{
                marginTop: "3rem",
              }}
            >
              <div className="col c-col--lg-6">
                <div className="c-caption-container">
                  <div
                    className="c-caption"
                    style={{ padding: 0, marginBottom: "1.5rem" }}
                  >
                    <div className="c-caption-section">
                      {t("developmentandproduction")}
                    </div>
                    <div className="c-caption-title c-caption-large">
                      {t("production")}
                    </div>
                    <div className="c-caption-content">
                      {t("production-desc")}
                    </div>
                  </div>
                  <Link
                    to="/development-and-production/production"
                    className="c-button"
                  >
                    {t("details")}
                  </Link>
                </div>
              </div>
              <div className="col c-col--lg-6">
                <div className="c-ratio c-ratio--4-3">
                  <img
                    src={require("../../assets/content/production.9fb36f20.webp")}
                    alt="Product-2"
                    className="c-products-img"
                  />
                </div>
              </div>
            </div>
          </LazyLoad>
        </div>
        <div className="c-corporate">
          <LazyLoad height={'100%'} offset={10}>
            <div className="c-container ">
              <div className="c-corporate-wrapper">
                <div className="c-corporate-content-container">
                  <div className="c-corporate-content-wrapper">
                    <div className="c-caption c-caption-text-center">
                      <div className="c-caption-section">{t("corporate")}</div>
                      <div className="c-caption-title c-caption-large">
                        {t("history-tale")}
                      </div>
                      <div className="c-caption-content">
                        {t("history-tale-desc")}
                      </div>
                    </div>
                    <Link to="/corporate/history" className="c-button">
                      {t("details")}
                    </Link>
                  </div>
                </div>
                <div className="c-corporate-timeline">
                  <div style={{ overflow: "hidden", position: "relative" }}>
                    <div className="c-timeline-opacity-effect top" />
                    <div className="c-timeline-opacity-effect bot" />
                    <Timeline scrollable={true} />
                  </div>
                </div>
              </div>
            </div>
          </LazyLoad>
        </div>
        <div
          className="c-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="c-caption c-news"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="c-caption-section">{t("news")}</div>
            <div className="c-caption-title">{t("recentnews")}</div>
          </div>
          <LazyLoad className="c-news-wrapper">
            {News_EN.slice(0, 3).map((item) => (
              <Link to={language === "en" ? item.link : "/tr/" + item.link_tr} className="c-news-card" key={item.link}>
                <div className="c-news-card-image-container c-ratio--16-9 c-ratio">
                  <picture>
                    <img src={item.img} className="c-news-img" alt="#" />
                  </picture>
                </div>
                <div className="c-news-body">
                  <div className="c-news-title">{item.title}</div>
                  <div className="c-news-date">{item.date}</div>
                  <div className="c-news-content">{item.desc}</div>
                </div>
              </Link>
            ))}
          </LazyLoad>
          <Link
            to="/news"
            className="c-button"
            style={{
              alignSelf: "center",
              marginTop: "1rem",
            }}
          >
            {t("allnews")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
