import i18next from "i18next";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./About.styles.css";

const About = () => {
  const { t } = useTranslation();
  const language = i18next.language;
  const { lang } = useParams();


  useEffect(() => {
    // document.title = "About - Cevher";
    // document.title = `${t('about-title')} - Cevher ${t('group')}`;
    if (lang) {
      i18next.changeLanguage(lang);
    }
    const updateTitle = () => {
      const newTitle = `${t('about-title')} | Cevher ${t('group')}`;
      document.title = newTitle;
      const newDescription = t("corporate-1");
      // Update og:title
      const ogTitleMeta = document.querySelector('meta[property="og:title"]');
      if (ogTitleMeta) {
        ogTitleMeta.setAttribute("content", newTitle);
      } else {
        const meta = document.createElement("meta");
        meta.setAttribute("property", "og:title");
        meta.setAttribute("content", newTitle);
        document.head.appendChild(meta);
      }

      // Update og:description
      const ogDescriptionMeta = document.querySelector('meta[property="og:description"]');
      if (ogDescriptionMeta) {
        ogDescriptionMeta.setAttribute("content", newDescription);
      } else {
        const meta = document.createElement("meta");
        meta.setAttribute("property", "og:description");
        meta.setAttribute("content", newDescription);
        document.head.appendChild(meta);
      }

      // Update description
      const descriptionMeta = document.querySelector('meta[name="description"]');
      if (descriptionMeta) {
        descriptionMeta.setAttribute("content", newDescription);
      } else {
        const meta = document.createElement("meta");
        meta.setAttribute("name", "description");
        meta.setAttribute("content", newDescription);
        document.head.appendChild(meta);
      }

    };

    updateTitle();

    const handleLanguageChanged = () => {
      updateTitle();
    };

    i18next.on("languageChanged", handleLanguageChanged);

    return () => {
      i18next.off("languageChanged", handleLanguageChanged);
    };
  }, [lang]);

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/about.3e9d3400.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("corporate")}
              </div>
              <h1 className="c-caption-header">{t("corporate-about")}</h1>
            </div>
          </div>
        </div>
        <div className="c-container">
          <div className="c-row">
            <div className="col c-col--lg-8 c-col--offset-lg-2">
              <div style={{ marginTop: "3rem" }}>
                <p>
                  <strong style={{ textAlign: "left" }}>
                    {t("corporate-1")}
                  </strong>
                </p>
                <p>{t("corporate-2")}</p>
                <p>{t("corporate-3")}</p>
                <p>{t("corporate-4")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="c-container">
          <div className="c-row">
            <div className="col c-col--lg-10 c-col--offset-lg-1">
              <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                <div className="c-about-map-content">
                  <div className="c-row">
                    <div className="col col--lg-8 col--offset-lg-2">
                      <div className="c-row row--no-gutter">
                        <div className="col col--lg-6">
                          <div className="c-about-counter-wrapper">
                            <div className="c-about-map-counter">70+</div>
                            <div className="c-about-map-counter-label">
                              {t("corporate-years")}
                            </div>
                          </div>
                        </div>
                        <div className="col c-col--lg-6">
                          <div className="c-about-counter-wrapper">
                            <div className="c-about-map-counter">1000+</div>
                            <div className="c-about-map-counter-label">
                              {t("corporate-workers")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="c-map-image"
                  src={require("../../assets/content/map.57aff948.png")}
                  alt="Map"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
