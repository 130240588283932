import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const SupplierSustainability = () => {
    const { t } = useTranslation();
    const language = i18next.language;
    const {lang} =useParams();
  
    
    // useEffect(() => {
    //   if (lang) {
    //       i18next.changeLanguage(lang);
    //   }
    //   const updateTitle = () => {
    //       document.title = `${t("supplier-sustainability-policy")} - Cevher`;
    //     };
      
    //     updateTitle();
      
    //     const handleLanguageChanged = () => {
    //       updateTitle();
    //     };
      
    //     i18next.on("languageChanged", handleLanguageChanged);
      
    //     return () => {
    //       i18next.off("languageChanged", handleLanguageChanged);
    //     };
    //   }, [lang]);

    return (
        <div>
            <Meta title={t("supplier-sustainability-policy")} description={t("supplier-sustainability-policy")} lang={lang}/>
            <Navbar />
            <div className="c-wrapper">
                <div className="c-hero">
                    <div className="c-hero-background">
                        <picture>
                            <img
                                className="c-hero-image"
                                alt=""
                                src={require("../../assets/hero/supplier.jpg")}
                            />
                        </picture>
                    </div>
                    <div className="c-hero-body">
                        <div className="c-about-caption">
                            <div
                                className="c-caption-section"
                                style={{ color: "rgb(189, 80, 80)", textAlign: "center" }}
                            >
                                {t("sustainability")}
                            </div>
                            <h1 className="c-caption-header" style={{ color: "rgb(189, 80, 80)" }}>
                                {t("supplier-sustainability-policy")}
                            </h1>
                        </div>
                    </div>
                </div>
                <div
                    className="c-container"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "#000",
                        paddingTop: "3rem",
                        paddingBottom: "3rem",
                    }}
                >

                    <p>
                        {t("supplier-text-1")}
                    </p>
                    <p>
                        {t("supplier-text-2")}
                    </p>
                    <p>
                        <strong >
                            {t("principles")}
                        </strong>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1")}
                        </strong>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.1")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.1.1")}</li>
                            <li>{t("principles-title-1.1.2")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.2")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.2.1")}</li>
                            <li>{t("principles-title-1.2.2")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.3")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.3.1")}</li>
                            <li>{t("principles-title-1.3.2")}</li>
                            <li>{t("principles-title-1.3.3")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.4")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.4.1")}</li>
                            <li>{t("principles-title-1.4.2")}</li>
                            <li>{t("principles-title-1.4.3")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.5")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.5.1")}</li>
                            <li>{t("principles-title-1.5.2")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.6")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.6.1")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.7")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.7.1")}</li>
                            <li>{t("principles-title-1.7.2")}</li>
                            <li>{t("principles-title-1.7.3")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.8")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.8.1")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.9")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.9.1")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.10")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.10.1")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.11")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.11.1")}</li>
                            <li>{t("principles-title-1.11.2")}</li>
                            <li>{t("principles-title-1.11.3")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-1.12")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-1.12.1")}</li>
                            <li>{t("principles-title-1.12.2")}</li>
                            <ul > 
                            <li>-{t("principles-title-1.12.2.1")}</li>
                            <li>-{t("principles-title-1.12.2.2")}</li>
                            <li>-{t("principles-title-1.12.2.3")}</li>
                            </ul>
                        </ul>
                    </p>



                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2")}
                        </strong>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.1")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.1.1")}</li>
                            <li>{t("principles-title-2.1.2")}</li>
                            <li>{t("principles-title-2.1.3")}</li>
                            <li>{t("principles-title-2.1.4")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.2")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.2.1")}</li>
                            <li>{t("principles-title-2.2.2")}</li>
                            <li>{t("principles-title-2.2.3")}</li>

                        </ul>
                    </p>


                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.3")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.3.1")}</li>
                            <li>{t("principles-title-2.3.2")}</li>
                            <li>{t("principles-title-2.3.3")}</li>
                            <li>{t("principles-title-2.3.4")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.4")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.4.1")}</li>
                            <li>{t("principles-title-2.4.2")}</li>
                            <li>{t("principles-title-2.4.3")}</li>
                            <li>{t("principles-title-2.4.4")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.5")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.5.1")}</li>
                            <li>{t("principles-title-2.5.2")}</li>
                            <li>{t("principles-title-2.5.3")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.6")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.6.1")}</li>
                            <li>{t("principles-title-2.6.2")}</li>
                            <li>{t("principles-title-2.6.3")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.7")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.7.1")}</li>
                            <li>{t("principles-title-2.7.2")}</li>
                            <li>{t("principles-title-2.7.3")}</li>
                            <li>{t("principles-title-2.7.4")}</li>
                            <li>{t("principles-title-2.7.5")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.8")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.8.1")}</li>
                            <li>{t("principles-title-2.8.2")}</li>
                            <li>{t("principles-title-2.8.3")}</li>
                            <li>{t("principles-title-2.8.4")}</li>
                            <li>{t("principles-title-2.8.5")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.9")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.9.1")}</li>
                            <li>{t("principles-title-2.9.2")}</li>
                            <li>{t("principles-title-2.9.3")}</li>
                            <li>{t("principles-title-2.9.4")}</li>
                        </ul>
                    </p>


                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-2.10")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-2.10.1")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3")}
                        </strong>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.0")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.0.1")}</li>
                            <li>{t("principles-title-3.0.2")}</li>
                            <li>{t("principles-title-3.0.3")}</li>
                            <li>{t("principles-title-3.0.4")}</li>
                            <li>{t("principles-title-3.0.5")}</li>
                            <li>{t("principles-title-3.0.6")}</li>
                            <li>{t("principles-title-3.0.7")}</li>
                            <li>{t("principles-title-3.0.8")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.1")}
                        </strong>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.1.1")}
                        </strong>
                    </p>

                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.1.1-1")}</li>
                            <li>{t("principles-title-3.1.1-2")}</li>
                            <li>{t("principles-title-3.1.1-3")}</li>
                            <li>{t("principles-title-3.1.1-4")}</li>

                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.1.2")}
                        </strong>
                    </p>

                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.1.2-1")}</li>
                            <li>{t("principles-title-3.1.2-2")}</li>
                            <li>{t("principles-title-3.1.2-3")}</li>
                            <li>{t("principles-title-3.1.2-4")}</li>

                        </ul>
                    </p>


                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.1.3")}
                        </strong>
                    </p>

                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.1.3-1")}</li>
                            <li>{t("principles-title-3.1.3-2")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.1.4")}
                        </strong>
                    </p>

                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.1.4-1")}</li>
                            <li>{t("principles-title-3.1.4-2")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.1.5")}
                        </strong>
                    </p>

                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.1.5-1")}</li>
                            <li>{t("principles-title-3.1.5-2")}</li>
                            <li>{t("principles-title-3.1.5-3")}</li>
                            <li>{t("principles-title-3.1.5-4")}</li>

                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.2")}
                        </strong>
                    </p>
                    <p>
                        {t("principles-title-3.2-0")}
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.2.1")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.2.1-1")}</li>
                            <li>{t("principles-title-3.2.1-2")}</li>
                            <li>{t("principles-title-3.2.1-3")}</li>
                            <li>{t("principles-title-3.2.1-4")}</li>
                            <li>{t("principles-title-3.2.1-5")}
                                <ul style={{ marginTop: "10px", listStyleType: "circle" }}>

                                    <li>{t("principles-title-3.2.1-5-1")}</li>
                                    <li>{t("principles-title-3.2.1-5-2")}</li>
                                    <li>{t("principles-title-3.2.1-5-3")}</li>

                                </ul>


                            </li>

                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.2.2")}
                        </strong>
                    </p>
                    <p>

                        {t("principles-title-3.2.2-0")}

                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.2.2-1")}</li>
                            <li>{t("principles-title-3.2.2-2")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-3.2.3")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-3.2.3-1")}</li>

                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-4")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-4.1")}</li>
                            <li>{t("principles-title-4.2")}</li>
                            <li>{t("principles-title-4.3")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-5")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-5.1")}</li>
                            <li>{t("principles-title-5.2")}</li>

                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-6")}
                        </strong>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-6.1")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-6.1-1")}</li>
                            <li>{t("principles-title-6.1-2")}</li>
                            <li>{t("principles-title-6.1-3")}</li>

                        </ul>
                    </p>

                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-6.2")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-6.2-1")}</li>
                        </ul>
                    </p>
                    <p>
                        <strong style={{ textAlign: "start" }}>
                            {t("principles-title-6.3")}
                        </strong>
                    </p>
                    <p>
                        <ul style={{ marginTop: "10px", listStyleType: "circle" }}>
                            <li>{t("principles-title-6.3-1")}</li>
                        </ul>
                    </p>

                    <p>
                        <strong >
                            {t("objective-title")}
                        </strong>
                    </p>
                    <p>

                        {t("objective-1")}

                    </p>
                    <p>

                        {t("objective-2")}

                    </p>

                    <p>01.11.2024 </p>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <h3>Oğuz ÖZMEN</h3>
                                <p>CEO</p>
                            </div>
                        </div>
                        {/* <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <h3>Ozan BATU</h3>
                                <p>CFO</p>
                            </div>
                        </div> */}
                    </div>


                </div>
            </div>
        </div>
    );
};

export default SupplierSustainability;
