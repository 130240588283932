import GoogleMapReact from "google-map-react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./Contact.styles.css";

const Contact = () => {
    const { t } = useTranslation();
    const location = { lat: 38.335505972082615, lng: 27.117687784656294 };
    const language = i18next.language;
    const { lang } = useParams();

    const renderMarker = (map, maps) => {
        let marker = new maps.Marker({
            position: location,
            map,
            title: "CDS",
        });
        return marker;
    };

    // useEffect(() => {
    //     document.title = "Contact - Cevher";
    //     if (lang) {
    //         i18next.changeLanguage(lang);
    //     }
    //     const updateTitle = () => {
    //         document.title = `${t("contact-title")} - Cevher`;
    //       };

    //       updateTitle();

    //       const handleLanguageChanged = () => {
    //         updateTitle();
    //       };

    //       i18next.on("languageChanged", handleLanguageChanged);

    //       return () => {
    //         i18next.off("languageChanged", handleLanguageChanged);
    //       };
    //     }, [lang]);

    return (
        <div>
            <Meta title={t("contact")} description={t("contact-us")} lang={lang} />
            <Navbar />
            <div className="c-wrapper">
                <div className="c-hero">
                    <div className="c-hero-background">
                        <picture>
                            <img
                                className="c-hero-image"
                                alt=""
                                src={require("../../assets/hero/contact-us.f72e52ce.jpg")}
                            />
                        </picture>
                    </div>
                    <div className="c-hero-body">
                        <div className="c-about-caption">
                            <div
                                className="c-caption-section"
                                style={{ color: "#fff", textAlign: "center" }}
                            >
                                {t("contact")}
                            </div>
                            <h1 className="c-caption-header">{t("contact-us")}</h1>
                        </div>
                    </div>
                </div>
                <div
                    className="container"
                    style={{ marginTop: "6rem", marginBottom: "6rem" }}
                >
                    <div className="c-row">
                        <div
                            className="col c-col--lg-5"
                            style={{ boxSizing: "border-box" }}
                        >
                            <h3>{t("contact-information")}</h3>
                            <p>
                                {t("sustainability-iso-4")}
                                <br />
                            </p>
                            <div style={{ marginTop: "1.5rem" }}>
                                <div>
                                    <strong>{t("contact-phone")}:</strong>
                                    <a href="tel:+90 (232) 298 20 00">+90 (232) 298 20 00</a>
                                </div>
                                <div>
                                    <strong>{t("contact-fax")}:</strong> +90 (232) 298 20 98
                                </div>
                                <div>
                                    <strong>{t("contact-email")}:</strong>{" "}
                                    <a href="mailto:info@cevherwheels.com">
                                        info@cevherwheels.com
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img style={{ width: '100%', maxWidth: '600px', height: 'auto' }} src={require("../../assets/img/cevher_kart" + (language === 'en' ? '_en' : '') + ".webp")}></img>

                        </div>
                    </div>

                </div>
                <hr />
                <div
                    className="container"
                    style={{ marginTop: "6rem" }}
                >
                    <div className="c-row">

                        <div
                            className="col c-col--lg-5"
                            style={{ boxSizing: "border-box" }}
                        >


                            <div
                                style={{ boxSizing: "border-box" }}
                            >
                                <GoogleMapReact
                                    bootstrapURLKeys={{
                                        key: "AIzaSyBh0HCRB3csoLLRIwWNGJtPtys3v5n2g0M",
                                    }}
                                    defaultCenter={location}
                                    defaultZoom={15}
                                    onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
                                    style={{ height: 450 }}
                                />
                            </div>
                        </div>
                        <div
                            className="col c-col--lg-6 c-col--offset-lg-1"
                            style={{ boxSizing: "border-box" }}
                        >

                            <h3>{t("contact-form")}</h3>
                            <p>{t("contact-1")}</p>
                            <form
                                action="https://formsubmit.co/info@cevherwheels.com"
                                autoComplete="off"
                                method="POST"
                                encType="multipart/form-data"
                            >
                                <input
                                    type="hidden"
                                    name="_subject"
                                    value="Cevher Career Submission"
                                />
                                <input
                                    type="hidden"
                                    name="_next"
                                    value="https://www.cevher.com/contact"
                                />
                                <input type="hidden" name="_captcha" value="false" />
                                <input type="hidden" name="_template" value="table" />
                                <div
                                    className="c-row"
                                    style={{
                                        boxSizing: "border-box",
                                    }}
                                >
                                    <div
                                        className="col c-col--lg-6"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <div style={{ marginTop: "1.5rem" }}>
                                            <span className="c-career-form-group-label">
                                                <label>{t("contact-name")} *</label>
                                            </span>
                                            <input
                                                name="first_name"
                                                type="text"
                                                required
                                                className="c-career-form-control"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col c-col--lg-6"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <div style={{ marginTop: "1.5rem" }}>
                                            <span className="c-career-form-group-label">
                                                <label>{t("contact-surname")} *</label>
                                            </span>
                                            <input
                                                name="last_name"
                                                type="text"
                                                required
                                                className="c-career-form-control"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col c-col--lg-6"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <div style={{ marginTop: "1.5rem" }}>
                                            <span className="c-career-form-group-label">
                                                <label>{t("contact-email")} *</label>
                                            </span>
                                            <input
                                                name="email"
                                                type="text"
                                                required
                                                className="c-career-form-control"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col c-col--lg-6"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <div style={{ marginTop: "1.5rem" }}>
                                            <span className="c-career-form-group-label">
                                                <label>{t("contact-phone")} *</label>
                                            </span>
                                            <input
                                                type="text"
                                                name="phone"
                                                required
                                                className="c-career-form-control"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="col c-col--lg-12"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <div style={{ marginTop: "1.5rem" }}>
                                            <span className="c-career-form-group-label">
                                                <label>{t("contact-message")} *</label>
                                            </span>
                                            <textarea
                                                name="message"
                                                type="text"
                                                required
                                                className="c-career-form-control"
                                                style={{ height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="c-col c-col--lg-12">
                                        <p>
                                            {t("contact-clarification-1")}
                                            <Link to="/sustainability/protection-of-personal-data">
                                                {t("contact-clarification-2")}
                                            </Link>
                                            {t("contact-clarification-3")}
                                        </p>
                                    </div>
                                    <div
                                        className="c-col c-col--lg-6"
                                        style={{ boxSizing: "border-box" }}
                                    >
                                        <input
                                            type="submit"
                                            className="c-button"
                                            value={t("contact-submit")}
                                            style={{ marginTop: "1.5rem" }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
