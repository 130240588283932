import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { Meta } from "../../Components/Helpers/Meta";

const EnvAndEnergy = () => {
    const { t } = useTranslation();
    const language = i18next.language;
    const { lang } = useParams();


    // useEffect(() => {
    //   if (lang) {
    //       i18next.changeLanguage(lang);
    //   }
    //   const updateTitle = () => {
    //       document.title = `${t("sustainability-envenergy")} - Cevher`;
    //     };

    //     updateTitle();

    //     const handleLanguageChanged = () => {
    //       updateTitle();
    //     };

    //     i18next.on("languageChanged", handleLanguageChanged);

    //     return () => {
    //       i18next.off("languageChanged", handleLanguageChanged);
    //     };
    //   }, [lang]);

    return (
        <div>
            <Meta title={t("sustainability-envenergy")} description={t("sustainability-envenergy-1")} lang={lang} />
            <Navbar />
            <div className="c-wrapper">
                <div className="c-hero">
                    <div className="c-hero-background">
                        <picture>
                            <img
                                className="c-hero-image"
                                alt=""
                                src={require("../../assets/hero/environment-policy.deb9f609.jpg")}
                            />
                        </picture>
                    </div>
                    <div className="c-hero-body">
                        <div className="c-about-caption">
                            <div
                                className="c-caption-section"
                                style={{ color: "#fff", textAlign: "center" }}
                            >
                                {t("sustainability")}
                            </div>
                            <h1 className="c-caption-header">
                                {t("sustainability-envenergy")}
                            </h1>
                        </div>
                    </div>
                </div>
                <div
                    className="c-container"
                    style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
                >
                    <p>{t("sustainability-envenergy-1")}</p>
                    <p>{t("sustainability-envenergy-2")}</p>
                    <p>
                        <br />
                        <strong style={{ textAlign: "start" }}>{t("sustainability-envenergy-3")}</strong>
                        <br />
                    </p>
                    <ul style={{ listStyle: "inside" }}>
                        <li>{t("sustainability-envenergy-4")}</li>
                        <li>{t("sustainability-envenergy-5")}</li>
                        <li>{t("sustainability-envenergy-6")}</li>
                        <li>{t("sustainability-envenergy-7")}</li>
                        <li>{t("sustainability-envenergy-8")}</li>
                        <li>{t("sustainability-envenergy-9")}</li>
                    </ul>
                    <p>
                        <br />
                        <strong style={{ textAlign: "start" }}>{t("sustainability-envenergy-10")}</strong>
                    </p>
                    <p style={{ textAlign: "start" }}>
                        {t("sustainability-envenergy-10-2")}
                    </p>
                    <ul style={{ listStyle: "inside" }}>
                        <li>{t("sustainability-envenergy-11")}</li>
                        <li>{t("sustainability-envenergy-12")}</li>
                        <li>{t("sustainability-envenergy-13")}</li>
                        <li>{t("sustainability-envenergy-14")}</li>
                        <li>{t("sustainability-envenergy-15")}</li>
                    </ul>
                    <p>
                        <br />
                        {t("sustainability-envenergy-16")}
                    </p>
                </div>

                <div className="c-container">
                <div className="c-col--offset-lg-2 c-col--lg-8">
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                width: "100%",
                                justifyContent: "center",
                                gap: 8,
                            }}
                        >
                            <a
                                href={
                                    language === "en"
                                        ? require("../../assets/certificates/envandenergy-EN.png")
                                        : require("../../assets/certificates/envandenergy-TR.png")
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={
                                        language === "en"
                                            ? require("../../assets/certificates/envandenergy-EN.png")
                                            : require("../../assets/certificates/envandenergy-TR.png")
                                    }
                                    alt=""
                                    className="c-logo"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnvAndEnergy;
